var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loader" }, [
    _c(
      "div",
      { staticStyle: { "min-height": "160px" } },
      [
        _c("svg", { staticClass: "circular" }, [
          _c("circle", {
            staticClass: "path",
            attrs: {
              cx: "50",
              cy: "50",
              r: "20",
              fill: "none",
              "stroke-width": "3",
              "stroke-miterlimit": "10",
            },
          }),
        ]),
        _vm.showText
          ? [
              _vm.onPage === "cart-payment"
                ? _c("h3", { staticClass: "loader__text" }, [
                    _vm._v("Your payment is processing"),
                  ])
                : _vm.onPage === "activation"
                ? _c("h3", { staticClass: "loader__text" }, [
                    _vm._v("Activating account..."),
                  ])
                : _vm.onPage === "order-review"
                ? _c("h3", { staticClass: "loader__text" }, [
                    _vm._v("Fetching data..."),
                  ])
                : _c("h3", { staticClass: "loader__text" }, [
                    _vm._v("Loading..."),
                  ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }