var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view view-orderReview" }, [
    !_vm.order
      ? _c(
          "section",
          { staticClass: "section section--fullScreen section--flexCenter" },
          [
            _vm.isLoading
              ? _c(
                  "div",
                  { staticClass: "container container--xs" },
                  [
                    _c("VLoadSpinner", {
                      attrs: { "on-page": "order-review" },
                    }),
                  ],
                  1
                )
              : _c("div", { staticClass: "container container--xs" }, [
                  _c(
                    "div",
                    { staticClass: "wrapper" },
                    [
                      _c("h2", { staticClass: "textIsError" }, [
                        _vm._v("Error!"),
                      ]),
                      _c("h4", [
                        _vm._v(
                          "We don't have any records for Order ID: " +
                            _vm._s(_vm.orderId)
                        ),
                      ]),
                      _vm.IS_MARKETPLACE
                        ? _c(
                            "router-link",
                            {
                              staticClass: "btn",
                              attrs: { to: { name: "Support" } },
                            },
                            [_vm._v("Hit Up Support")]
                          )
                        : _vm.IS_ELITEPAGE
                        ? _c(
                            "router-link",
                            {
                              staticClass: "btn btn--border",
                              attrs: { to: { name: "Home", hash: "#contact" } },
                            },
                            [_vm._v("Hit Up Support")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
          ]
        )
      : _c(
          "section",
          {
            staticClass: "section",
            class: [
              _vm.playerIsShowing,
              _vm.dealBarIsShowing,
              _vm.fullScreenOnSmallOrders,
            ],
          },
          [
            _c(
              "div",
              { staticClass: "container container--xs" },
              [
                _c("CartHeading", {
                  attrs: {
                    title: "Order Review",
                    subtitle:
                      "Thanks for your purchase, download your files here.",
                  },
                }),
                _vm.order
                  ? _c(
                      "div",
                      { staticClass: "cartDownload" },
                      [
                        _vm._l(_vm.order.bought_beats, function (item) {
                          return _c(
                            "div",
                            { staticClass: "cartDownload__item" },
                            [
                              _c(
                                "div",
                                { staticClass: "cartDownload__beatName" },
                                [
                                  _vm._v(
                                    _vm._s(item.producer_name) +
                                      " - " +
                                      _vm._s(item.beat_name)
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "cartDownload__desktop" },
                                [
                                  item.file_mp3_url
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "btn btn--border",
                                          attrs: {
                                            href:
                                              "" +
                                              _vm.BASE_URL +
                                              item.file_mp3_url,
                                          },
                                        },
                                        [
                                          _c("icon", {
                                            attrs: { name: "download" },
                                          }),
                                          _vm._v("MP3"),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.file_wav_url
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "btn btn--border",
                                          attrs: {
                                            href:
                                              "" +
                                              _vm.BASE_URL +
                                              item.file_wav_url,
                                          },
                                        },
                                        [
                                          _c("icon", {
                                            attrs: { name: "download" },
                                          }),
                                          _vm._v("WAV"),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.file_trackout_url
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "btn btn--border",
                                          attrs: {
                                            href:
                                              "" +
                                              _vm.BASE_URL +
                                              item.file_trackout_url,
                                          },
                                        },
                                        [
                                          _c("icon", {
                                            attrs: { name: "download" },
                                          }),
                                          _vm._v("Trackout"),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn btn--xs btn--text",
                                      attrs: { target: "_blank" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openPdfContact({
                                            beatId: item.sku,
                                            email: _vm.order.email,
                                          })
                                        },
                                      },
                                    },
                                    [
                                      _c("icon", {
                                        attrs: { name: "download" },
                                      }),
                                      _vm._v("Contract"),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "cartDownload__mobile" },
                                [
                                  item.file_mp3_url
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "cartDownload__iconWrapper",
                                          attrs: {
                                            href:
                                              "" +
                                              _vm.BASE_URL +
                                              item.file_mp3_url,
                                          },
                                        },
                                        [
                                          _c("icon", {
                                            attrs: { name: "mp3" },
                                          }),
                                          _c("p", [_vm._v("MP3")]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.file_wav_url
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "cartDownload__iconWrapper",
                                          attrs: {
                                            href:
                                              "" +
                                              _vm.BASE_URL +
                                              item.file_wav_url,
                                          },
                                        },
                                        [
                                          _c("icon", {
                                            attrs: { name: "wav" },
                                          }),
                                          _c("p", [_vm._v("WAV")]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.file_trackout_url
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "cartDownload__iconWrapper",
                                          attrs: {
                                            href:
                                              "" +
                                              _vm.BASE_URL +
                                              item.file_trackout_url,
                                          },
                                        },
                                        [
                                          _c("icon", {
                                            attrs: { name: "stems" },
                                          }),
                                          _c("p", [_vm._v("Trackout")]),
                                          _c("p", { staticClass: "infoText" }, [
                                            _vm._v("(more than 200MB)"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "cartDownload__iconWrapper",
                                      attrs: { target: "_blank" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openPdfContact({
                                            beatId: item.sku,
                                            email: _vm.order.email,
                                          })
                                        },
                                      },
                                    },
                                    [
                                      _c("icon", {
                                        attrs: { name: "document-o" },
                                      }),
                                      _c("p", [_vm._v("Cont.")]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        _vm._l(_vm.order.bought_sound_kits, function (item) {
                          return _c(
                            "div",
                            { staticClass: "cartDownload__item" },
                            [
                              _c(
                                "div",
                                { staticClass: "cartDownload__beatName" },
                                [
                                  _vm._v(
                                    _vm._s(item.producer_name) +
                                      " - " +
                                      _vm._s(item.name)
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "cartDownload__desktop" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn btn--border",
                                      attrs: {
                                        href: "" + _vm.BASE_URL + item.file_url,
                                      },
                                    },
                                    [
                                      _c("icon", {
                                        attrs: { name: "download" },
                                      }),
                                      _vm._v("ZIP"),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "cartDownload__mobile" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "cartDownload__iconWrapper",
                                      attrs: {
                                        href: "" + _vm.BASE_URL + item.file_url,
                                      },
                                    },
                                    [
                                      _c("icon", {
                                        attrs: { name: "download" },
                                      }),
                                      _c("p", [_vm._v("ZIP")]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "cartNavigation" },
                  [
                    _vm.isAuthenticated && _vm.IS_MARKETPLACE
                      ? [
                          _c(
                            "router-link",
                            {
                              staticClass: "btn btn--secondary",
                              attrs: { to: { name: "Purchases" } },
                            },
                            [_vm._v("Go to my licenses")]
                          ),
                        ]
                      : [
                          _c("div", { staticClass: "h-textCenter" }, [
                            _c("p", { staticClass: "p--lead" }, [
                              _vm._v(
                                "An email has also been sent to " +
                                  _vm._s(_vm.order.email) +
                                  " with your order confirmation and download links."
                              ),
                            ]),
                          ]),
                        ],
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }